import axios from 'axios';
import { Orders } from '~api/orders';
import { uploadAsync } from 'expo-file-system';
//
import { isNative } from '~global/screens';
import { Uploader } from '~api/uploader';

const Config = require('~config/default.env').default;

const getSearchCount = (params) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/search/count`, params)
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getSearch = (params) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/search`, params)
    .then(({ data }) => data)
    .catch(console.log);

const getSearchResult = (params) =>
  axios
    .all([getSearchCount({ params }), getSearch({ params })])
    .then((data) => data)
    .catch(console.log);

const getOrdersCount = (params) =>
  Orders.countRequest({ params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getOrders = (params) =>
  Orders.readRequest({ params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getCategories = (params) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/categories`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getPosts = (params) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/posts`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const upload = ({ filename, type, uri }) =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/pre-signed-url`, {
      params: {
        fileName: filename,
        contentType: type,
      },
    })
    .then(({ data: { data } }) => {
      isNative
        ? uploadAsync(data.signedUrl, uri, {
            httpMethod: 'PUT',
            mimeType: type,
            headers: { 'Content-Type': type },
          })
        : Uploader.putImageS3Request(data.signedUrl, uri, type, filename);
      return data.url;
    });

const uploadImages = (images) =>
  axios.all(images.map((image) => upload(image)));

const getLikes = ({ postId, params }) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/post/${postId}/likes`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const postLikes = ({ postId }) =>
  axios
    .post(`${Config.MS_BJ_PUBLIC_API_URL}/post/${postId}/likes`)
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getDislikes = ({ postId, params }) =>
  axios
    .get(`${Config.MS_BJ_PUBLIC_API_URL}/post/${postId}/dislikes`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const postDislikes = ({ postId, payload }) =>
  axios
    .post(`${Config.MS_BJ_PUBLIC_API_URL}/post/${postId}/dislikes`, payload)
    .then(({ data: { data } }) => data)
    .catch(console.log);

const fetchLikes = (payload) =>
  axios
    .all([getLikes(payload), getDislikes(payload)])
    .then((data) => data)
    .catch(console.log);

const postFeedback = (payload) =>
  axios
    .post(`${Config.MS_BJ_PUBLIC_API_URL}/feedback-forms`, payload)
    .then(({ data: { data } }) => data)
    .catch(console.log);

export default {
  getSearchCount,
  getSearch,
  getSearchResult,
  getOrdersCount,
  getOrders,
  getCategories,
  getPosts,
  uploadImages,
  postLikes,
  postDislikes,
  fetchLikes,
  postFeedback,
};
