import axios from 'axios';
const Config = require('~config/default.env').default;

const getCountries = (params) =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/countries`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getProvinces = () =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/provinces`)
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getCities = (params) =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/cities`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

const getDistricts = (params) =>
  axios
    .get(`${Config.MS_SOCO_PUBLIC_API_URL}/districts`, { params })
    .then(({ data: { data } }) => data)
    .catch(console.log);

export default {
  getCountries,
  getProvinces,
  getCities,
  getDistricts,
};
