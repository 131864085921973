const Config = require('~config/default.env').default;
import axios from 'axios';

const countRequest = (payload) => {
  return axios.get(`${Config.MS_ORDER_API_URL}/user/me/orders/count`, payload);
};

const readRequest = (payload) => {
  return axios.get(`${Config.MS_ORDER_API_URL}/user/me/orders`, payload);
};

const stateRequest = () => {
  return axios.get(`${Config.MS_ORDER_API_URL}/order-state`, {
    params: {
      field: 'name,color,_id',
    },
  });
};

const createRequest = (payload) => {
  return axios.post(`${Config.MS_ORDER_API_URL}/users/me/orders`, payload);
};

const repayCC = (payload) => {
  return axios.post(`${Config.MS_ORDER_API_URL}/order/repaycc`, payload);
};

const paymentConfirmRequest = (payload) => {
  return axios.post(
    `${Config.MS_PAYMENT_API_URL}/payment-confirms/${payload._id}`,
    payload.payload,
  );
};

const bankAccountRequest = (payload) => {
  return axios.post(`${Config.MS_PAYMENT_API_URL}/bank-accounts`, payload);
};

const trackRequest = (payload) => {
  return axios.get(`${Config.MS_ORDER_API_URL}/order/${payload._id}/track`);
};

const packageReceivedRequest = (payload) => {
  return axios.put(
    `${Config.MS_ORDER_API_URL}/order/${payload._id}/status/${payload.delivered_id}`,
  );
};

const retryRequest = (payload) => {
  return axios.put(`${Config.MS_ORDER_API_URL}/order/${payload}/retry`);
};

const retryByPhoneNumberRequest = (payload) => {
  return axios.post(`${Config.MS_PAYMENT_API_URL}/payments`, payload);
};

const getRecentlyBought = (payload) => {
  return axios.get(
    `${Config.MS_ORDER_API_URL}/users/me/recently-bought`,
    payload,
  );
};

export const Orders = {
  countRequest,
  readRequest,
  stateRequest,
  createRequest,
  bankAccountRequest,
  paymentConfirmRequest,
  trackRequest,
  packageReceivedRequest,
  retryRequest,
  retryByPhoneNumberRequest,
  getRecentlyBought,
  repayCC,
};
