/* eslint-disable no-console */
import axios from 'axios';
const Config = require('~config/default.env').default;

const getCareersRequest = (params) =>
  axios
    .get(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/careers`, params)
    .then(({ data: { data } }) => data)
    .catch(console.log);

export default {
  getCareersRequest,
};
