/*
Author: Nishant Singh
Description: All method name should be followed by suffix Request
*/

const Config = require('~config/default.env').default;
import axios from 'axios';

const userCartRequest = () => {
  return axios.get(`${Config.MS_CART_API_URL}/user/me/carts`);
};

const cartProductCountRequest = () => {
  return axios.get(`${Config.MS_CART_API_URL}/user/me/carts/count`);
};

const addProductToCartRequest = (payload) => {
  return axios.post(`${Config.MS_CART_API_URL}/user/me/carts`, payload);
};

const cartReorderRequest = (payload) => {
  return axios.post(`${Config.MS_CART_API_URL}/carts/reorder`, payload);
};

const applyVoucherRequest = (payload) => {
  return axios.post(`${Config.MS_CART_API_URL}/carts/voucher`, payload);
};

const cancelVoucherRequest = (payload) => {
  return axios.delete(`${Config.MS_CART_API_URL}/carts/voucher`, payload);
};

const vouchersRequest = (params) => {
  return axios.get(`${Config.MS_CART_API_URL}/user/me/vouchers`, { params });
};

const vouchersCountRequest = (params) => {
  return axios.get(`${Config.MS_CART_API_URL}/vouchers/count`, { params });
};

const specialPriceProductsRequest = () => {
  return axios.get(`${Config.MS_CART_API_URL}/special-price-products`);
};

const gwpProductsRequest = () => {
  return axios.get(`${Config.MS_CART_API_URL}/free-gwp-products`);
};

const pointsRequest = (params) => {
  return axios.get(`${Config.MS_CART_API_URL}/points`, { params });
};

const applyRedeemPointsRequest = (payload) => {
  return axios.post(`${Config.MS_CART_API_URL}/carts/points`, payload);
};

const cancelRedeemPointsRequest = (payload) => {
  return axios.delete(`${Config.MS_CART_API_URL}/carts/points`, payload);
};

const getExtrabagsRequest = (params) => {
  return axios.get(`${Config.MS_CART_API_URL}/extrabags`, { params });
};

const toggleExtrabagRequest = (payload) => {
  return axios.post(`${Config.MS_CART_API_URL}/extrabags`, payload);
};

const getVouchersProduct = (productId) => {
  return axios.get(`${Config.MS_CART_API_URL}/vouchers/products/${productId}`, {
    params: {
      filter: { is_display_sociolla: true, use_sort: true },
      sort: '-created_at',
      skip: 0,
      limit: 20,
    },
  });
};

const getVouchersByCategoryIdRequest = (id, params) => {
  return axios.get(`${Config.MS_CART_API_URL}/category/${id}/vouchers`, {
    params,
  });
};

const updateCartAddress = (cartId, addressId) => {
  return axios.put(
    `${Config.MS_CART_API_URL}/carts/${cartId}/address/${addressId}`,
  );
};

export const Cart = {
  userCartRequest,
  cartProductCountRequest,
  addProductToCartRequest,
  applyVoucherRequest,
  vouchersRequest,
  vouchersCountRequest,
  specialPriceProductsRequest,
  gwpProductsRequest,
  pointsRequest,
  applyRedeemPointsRequest,
  cancelRedeemPointsRequest,
  cancelVoucherRequest,
  getExtrabagsRequest,
  toggleExtrabagRequest,
  cartReorderRequest,
  getVouchersProduct,
  getVouchersByCategoryIdRequest,
  updateCartAddress,
};
